import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './counterSlice'
import filterReducer from './filterSlice'
import storyReducer from './storySlice'
import statsReducer from './statsSlice'
import authSliceReducer from './authSlice'


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    filter: filterReducer,
    mystories: storyReducer,
    stats: statsReducer,
    authstate: authSliceReducer,


  },
})
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { AppBar, Tab, Tabs, TextField } from '@material-ui/core';


import PrimarySearchAppBar from './templates/appbar';

import Home from './templates/home';
import AddSource from './templates/addsource';
import ViewSources from './templates/viewsources';
import SignUp from './templates/signup';
import Login from './templates/login';


import './App.css';

function App() {


const hstyleObj = {

  fontSize: 14,
  color: "white",
  fontWeight: 'bold',
  textAlign: "center",
  //paddingLeft: "10px",
  paddingTop: "0px",




}

const wordstyleObj = {

  fontSize: 8,
  color: "grey",
  fontWeight: 'bold',
  textAlign: "center",
  //paddingLeft: "10px",
  paddingTop: "0px",




}

  return (
    <Router>
    <div className="App">
    <PrimarySearchAppBar></PrimarySearchAppBar>

      <Routes>
                <Route exact path='/' element={< Home />}></Route>
                <Route exact path='/addsource' element={< AddSource />}></Route>
                <Route exact path='/viewsources' element={< ViewSources />}></Route>
                <Route exact path='/signup' element={< SignUp />}></Route>
                <Route exact path='/login' element={< Login />}></Route>
                {/* <Route exact path='/contact' element={< Contact />}></Route> */}
      </Routes>

    </div>
    </Router>
  );
}

export default App;

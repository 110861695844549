import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { styled, alpha } from '@mui/material/styles';

import moment, { ISO_8601 } from 'moment';


import { useSelector, useDispatch } from 'react-redux';
import { decrement, increment } from '../redux/counterSlice';
import { refresh, clear } from '../redux/storySlice';
import { setStats } from '../redux/statsSlice';




let server_url = "https://mapi2.newsy.today";

async function fetch_stories(timestamp){


  //let server_url = 'http://127.0.0.1:3401';
  return new Promise(function (resolve,reject){

    try{
                    fetch(server_url + "/stories?timestamp='" + timestamp + "'")
                    .then(response => response.json())
                    .then(function(data){
                            
                            //console.log(data);
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })



}



async function fetch_searchit(searchterm, timestamp){


  //let server_url = 'http://127.0.0.1:3401';
  return new Promise(function (resolve,reject){

    try{
                    fetch(server_url + "/searchit?searchterm=" + searchterm + "&timestamp=" + timestamp)
                    .then(response => response.json())
                    .then(function(data){
                            
                            //console.log(data);
                            resolve(data);
                    
                    });
    }
    catch(err){
            console.log(err);
            reject(err);
    }

    })



}




const StoriesList = (props) => { 


  const [CurrentTime,setCurrentTime] = useState();
  const [CurrentTimestamp,setCurrentTimestamp] = useState();
  const [RunReload, setRunReload] = useState("run");
  const [Stories, setStories] = useState([]);
  const [NumStories, setNumStories] = useState(0);

  const [StoriesGrouped, setStoriesGrouped] = useState([]);
  const [NumStoriesGrouped, setNumStoriesGrouped] = useState(0);

  const [Sources,setSources] = useState([]);
  
  const [TopKeywords, setTopKeywords] = useState([]);

  const [ShowText, setShowText] = useState(false);
  const [SentimentVal,setSentimentVal] = useState(2);

  const [SearchTerm, setSearchTerm] = useState();
  const [SearchTimeStamp, setSearchTimeStamp] = useState(0);

  const count = useSelector((state) => state.counter.value);
  const filter = useSelector((state) => state.filter.value);
  const stats = useSelector((state) => state.filter.value);
  const dispatch = useDispatch()


 
  useEffect(() => {

    if(filter == 1){
      setShowText(true);
    }
    else{
      setShowText(false);
    }
   

  });





 


  //let jdata = props.jdata;
  //let jjdata = JSON.parse(jdata);

  //console.log('ppp: ' + JSON.stringify(props));

  console.log((props.jdata.length));
  let wjson = props.jdata;
  let stories = new Array();
  let i = 0; const iMax = wjson.length -1; for(; i <= iMax; i++){
      //if(parseInt(wjson[i].sentiment) <= -4){
      stories.push({title: wjson[i].title, firstseen: wjson[i].firstseen, lastseen: wjson[i].lastseen, sentiment: wjson[i].sentiment, hash: wjson[i].hash, url: wjson[i].url, feed_title: wjson[i].feed_title});
      //}
  }
  //console.log(props.jdata.length);

  //console.log(jdata);




  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }));





  

  
  return (
    <div className="trips">

{/*
<Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
 </Search>

<Tooltip title="Toggle Filter">

     <IconButton size="large" aria-label="search" color="inherit"  onClick={findall_sentiment}>
          <Badge badgeContent={0} color="error">
            <FilterListIcon />
          </Badge>
        </IconButton>
  </Tooltip>
*/}




      {stories.map(function(item, i){   
      //let url = 'https://lms-api.isnet.co.za/maps/?tripid=' + item.tripid;
      let timeSeconds = Math.round((moment().unix() - parseInt(item.firstseen))/60);

     
     // let bgColor =  "#f0f2f5";
     const styleObj = {

      fontSize: 11,
      color: "grey",
      textAlign: "left",
      paddingLeft: "10px",
      paddingTop: "0px",
      textDecoration: 'none',
      //backgroundColor: "#f0f2f5"
  }

  const storyObj = {

    fontSize: 14,
    fontWeight: "bold",
    //color: "#4a54f1",
    color: "#3D4849",
    textAlign: "left",
    paddingLeft: "10px",
    paddingTop: "0px",
    textDecoration: 'none',
    //backgroundColor: "#f0f2f5"

    
}
  
let tagtype = "neutral";
if(item.sentiment < 0){
  tagtype = "bad";
}
if(item.sentiment > 0){
  tagtype = "good";
}


     let bgColor = "";
      if (i % 2 == 0){ 

       
       
     // console.log('Number is even');


      bgColor =  "#f0f2f5";
      styleObj.backgroundColor = "#f0f2f5";
      storyObj.backgroundColor = "#f0f2f5";

        //red
        if(item.sentiment <= -4){
          styleObj.backgroundColor = "#FFC9C9";
          storyObj.backgroundColor = "#FFC9C9";
        }
        //green
        if(item.sentiment >= 4){
          styleObj.backgroundColor = "#CAFFC9";
          storyObj.backgroundColor = "#CAFFC9";
        }

      /*
              return(<div key={i} name={item.hash} style={{backgroundColor:{bgColor}}}>
          <div style={{backgroundColor:{bgColor}}}><p style={storyObj}><a href={item.url} style={{ textDecoration: 'none' }} target="_blank">{item.title}</a></p>
          <p style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</p></div>
        </div>
          )

      */

           {/*
            <p style={storyObj}><a href={item.url} style={{ textDecoration: 'none' }} target="_blank">{item.title}</a>
          
            <br></br>
            <b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}
            
            </p>
            */}

           
            

            if(ShowText){
              return(
            
                
            
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 
          
                  
                )
              }
              else{

                if(item.sentiment < -SentimentVal){
                  return(

                   
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 


                  );
                }

                if(item.sentiment > SentimentVal){
                  return(

                   
                    <dl style={storyObj} key={i} tagtype={tagtype}>
                      <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                      <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                    </dl> 


                  );
                }

              }

      }
      else{



      //console.log("Number is odd");
        bgColor = "#FFFFFF";
        storyObj.backgroundColor = "#FFFFFF";
        styleObj.backgroundColor = "#FFFFFF";



        if(item.sentiment <= -4){
          styleObj.backgroundColor = "#FFC9C9";
          storyObj.backgroundColor = "#FFC9C9";
        }
        if(item.sentiment >= 4){
          styleObj.backgroundColor = "#CAFFC9";
          storyObj.backgroundColor = "#CAFFC9";
        }


        /*

        return(

          
          <dl style={storyObj} key={i} tagtype={tagtype}>
                <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none', color: "#3D4849" }} target="_blank">{item.title}</a></dt>
                <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
              </dl> 
       

        
        
          )*/

          if(ShowText){
            return(
          
              
          
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 
        
                
              )
            }
            else{

              if(item.sentiment < -SentimentVal){
                return(

                 
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 


                );
              }

              if(item.sentiment > SentimentVal){
                return(

                 
                  <dl style={storyObj} key={i} tagtype={tagtype}>
                    <dt style={storyObj}><a href={item.url} style={{ textDecoration: 'none',  color: "#3D4849"}} target="_blank">{item.title}</a></dt>
                    <dt style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</dt>
                  </dl> 


                );
              }
            }

        /*
        return(<div key={i} name={item.hash} style={{backgroundColor:{bgColor}}}>
          <div style={{backgroundColor:{bgColor}}}><p style={storyObj}><a href={item.url} style={{ textDecoration: 'none' }} target="_blank">{item.title}</a></p>
          <p style={styleObj}><b>Sentiment:</b> {item.sentiment} | <b>Age</b>:{timeSeconds} minutes ago | <b>Feed</b>: {item.feed_title}</p></div>
        </div>
          )
          */

      }

 



      })}
    
    </div>
  )
  

 
  /*
 return(
  <li>test</li>

 )
 */
}






function Home (){




  const [CurrentTime,setCurrentTime] = useState();
  const [CurrentTimestamp,setCurrentTimestamp] = useState();
  const [RunReload, setRunReload] = useState("run");
  const [Stories, setStories] = useState([]);
  const [NumStories, setNumStories] = useState(0);

  const [StoriesGrouped, setStoriesGrouped] = useState([]);
  const [NumStoriesGrouped, setNumStoriesGrouped] = useState(0);

  const [Sources,setSources] = useState([]);
  
  const [TopKeywords, setTopKeywords] = useState([]);

  const [ShowText, setShowText] = useState(false);
  const [SentimentVal,setSentimentVal] = useState(2);

  const [SearchTerm, setSearchTerm] = useState();
  const [SearchTimeStamp, setSearchTimeStamp] = useState(0);

 

  const mystories = useSelector((state) => state.mystories.value);
  const stats = useSelector((state) => state.stats.value);

  const dispatch = useDispatch()



 
 
 useEffect(() => {





  if(mystories == 1){
    setRunReload("run");
  }    


  if(mystories.term){

    console.log("lloading: " + mystories.term);
    setSearchTerm(mystories.term);
    dispatch(clear());
    search2(mystories.term);

  }
      
        
  if(RunReload == "run"){

    console.log('useEffect Run Reload called');
  

    /*
            async function get_topkeywords(){

              


        

                    let keywords = await fetch_top_keywords();

                

                    console.log(keywords)
                    setTopKeywords(keywords);

                    
            }
                    

            get_topkeywords();
            */



            async function get_stories(){

              console.log('updating stories');


              let mytimestamp = moment().unix() - 14400;

            //setCurrentTimestamp(moment().unix() - 3600); //Last hour


              let stories = await fetch_stories(mytimestamp);
              //console.log(stories);
              setStories(stories);
              setNumStories(stories.length);

              dispatch(setStats({NumStories: stories.length}));

              dispatch(clear());

            }
            get_stories();




/*

            async function get_stories_grouped(){

              console.log('updating stories');


              let mytimestamp = moment().unix() - 14400;

            //setCurrentTimestamp(moment().unix() - 3600); //Last hour


              let stories = await fetch_stories_grouped(mytimestamp);
              console.log(stories);
              setStoriesGrouped(stories);
              setNumStoriesGrouped(stories.length);

            }
            get_stories_grouped();

*/

/*

            async function get_sources(){

              console.log('updating sources');
              let mysources = await fetch_sources();

              setSources(mysources);
              console.log(mysources);
         

            }
            get_sources();
            */


            setRunReload(false);


      }
     
        
      
    


});



async function search(){

  let stories = await fetch_searchit(SearchTerm, SearchTimeStamp);

  //let stories = await fetch_stories(mytimestamp);
  //console.log(stories);
  setStories(stories);
  setNumStories(stories.length);

  console.log(stories);

}

async function search2(searchTerm){

  let stories = await fetch_searchit(searchTerm, SearchTimeStamp);

  //let stories = await fetch_stories(mytimestamp);
  //console.log(stories);
  setStories(stories);
  setNumStories(stories.length);
  dispatch(setStats({NumStories: stories.length}));

  console.log(stories);

}







    


    return (
    
      
      <StoriesList jdata={Stories}></StoriesList>

      
    );
   }
    
   export default Home;